import Vue                            from 'vue'
import VueScrollTo                    from 'vue-scrollto'
import { BootstrapVue, IconsPlugin }  from 'bootstrap-vue'
import VueSweetalert2                 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import App                            from './App.vue'
import router                         from './router'

Vue.use(VueScrollTo)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueSweetalert2);
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
