<template>
  <div class="content-tarifas" id="tarifas">
    <h4>Tarifas</h4>
    <hr />
    <div class="container-tables">
      <table class="table table-striped shadow">
      <thead class="bg-success">
        <tr>
          <th colspan="2" class="title">Tarifas Hotel Campestre Kaidaly</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="tarifa in tarifas" :key="tarifa.id">
          <td scopre="row" class="text">{{ tarifa.name }}</td>
          <td class="text-success text">${{ tarifa.price }}</td>
        </tr>
      </tbody>
    </table>
    <table class="table table-striped shadow">
      <thead class="bg-warning">
        <tr>
          <th colspan="2" class="title">Menú Especial</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="menu in menuSpecial" :key="menu.id">
          <td scopre="row" class="text">{{ menu.name }}</td>
        </tr>
      </tbody>
    </table>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Tarifas',
    data(){
      return {
        tarifas:[
          { id:0, name: 'Hospedaje por persona en grupos', price: '50.000'},
          { id:1, name: 'Hospedaje por pareja', price: '105.000'},
          { id:2, name: 'Hospedaje tres personas', price: '150.000'},
          { id:3, name: 'Pasadia por persona', price: '15.000'},
          { id:4, name: 'Desayuno por persona', price: '10.000'},
          { id:5, name: 'Almuerzo por persona', price: '15.000'},
          { id:6, name: 'Especialidades desde', price: '20.000'},
          { id:7, name: 'Cena por persona', price: '15.000'},
          { id:8, name: 'Refrigerio por persona', price: '8.000'},
          { id:9, name: 'Salón de eventos por persona', price: '10.000'},
        ],
        menuSpecial:[
          {id: 0, name: 'Sancocho'},
          {id: 1, name: 'Carne a la llanera'},
          {id: 2, name: 'Bandeja criolla a la llanera'},
          {id: 3, name: 'Arroz llanero'},
          {id: 4, name: 'Carne al horno'},
          {id: 5, name: 'Hayacas'},
          {id: 6, name: 'Todo tipo de comida nacional e internacional'},
          {id: 7, name: 'Comidas especiales para reuniones o eventos'},
          {id: 8, name: 'Para sus Reuniones y/o Eventos ofrecemos servicio de Buffet por persona $ 35.000'},
        ]
      }
    }
}
</script>

<style scoped>
  .content-tarifas{
      text-align: center;
  }
  .title{
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
  }
  .text{
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
  }
  .container-tables{
    display: inline-flex;
  }
  .table{
    text-align: center;
  }
  .table:first-child{
    margin-right: 50px;
  }
  thead{
    font-style: italic;
  }
  @media screen and (max-width: 720px){
    .container-tables{
      display: block;
    }
  }
</style>