<template>
  <div class="w-100 p-3 footerBack">
      <small><b><i>Desarrollado por <a href="mailto: andressaray14@gmail.com">andressaray14@gmail.com</a></i></b></small>
  </div>
</template>

<script>
export default {
    name: 'BackFooter'
}
</script>

<style scoped>
    .footerBack{
        position:fixed;
        bottom:0;
        height:auto;
        width:100%;
        text-align:center;
        z-index: 1;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        background-color: #ff9a02;
    }
</style>