<template>
  <div id="nuestraFinca">
    <h4>Servicios</h4>
    <hr />
    <br />
    <div class="container-servicios">
      <div class="p-1 shadow rounded">
        <p>
          <b><u>Hospedaje</u></b>
        </p>
        <img src="../assets/images/hotel.png" class="icon" alt="" />
      </div>
      <div class="p-1 shadow rounded">
        <p>
          <b><u>Eventos</u></b>
        </p>
        <img src="../assets/images/evento.png" class="icon" alt="" />
      </div>
      <div class="p-1 shadow rounded">
        <p>
          <b><u>Piscina</u></b>
        </p>
        <img src="../assets/images/piscina.png" class="icon" alt="" />
      </div>
    </div>
    <br />
    <footer class="subtitle"><u>Areas Sociales y Ecológicas</u></footer>
    <br />
    <div class="container-areas">
      <b-carousel
        id="carousel-1"
        :interval="2000"
        controls
        indicators
        fade
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333;"
      >
        <b-carousel-slide class="shadow" caption="Piscina">
          <template #img>
            <img
              class="d-block img-fluid w-100"
              style="width: 495px; height: 250px; object-fit: cover;"
              src="../assets/images/piscina-1.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
        <b-carousel-slide class="shadow" caption="Piscina">
          <template #img>
            <img
              class="d-block img-fluid w-100"
              style="width: 495px; height: 250px; object-fit: cover;"
              src="../assets/images/piscina-2.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
      </b-carousel>
      <!-- Salon de Eventos -->
      <b-carousel
        id="carousel-2"
        :interval="2000"
        controls
        indicators
        fade
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333;"
      >
        <b-carousel-slide class="shadow" caption="Salón de Eventos">
          <template #img>
            <img
              class="d-block img-fluid w-100"
              style="width: 495px; height: 250px; object-fit: cover;"
              src="../assets/images/salon-de-conferencias.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
        <b-carousel-slide class="shadow" caption="Salón de Eventos">
          <template #img>
            <img
              class="d-block img-fluid w-100"
              style="width: 495px; height: 250px; object-fit: cover;"
              src="../assets/images/salon-de-conferencias-1.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
         <b-carousel-slide class="shadow" caption="Salón de Eventos">
          <template #img>
            <img
              class="d-block img-fluid w-100"
              style="width: 495px; height: 250px; object-fit: cover;"
              src="../assets/images/salon-de-conferencias-2.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
         <b-carousel-slide class="shadow" caption="Salón de Eventos">
          <template #img>
            <img
              class="d-block img-fluid w-100"
              style="width: 495px; height: 250px; object-fit: cover;"
              src="../assets/images/salon-de-conferencias-3.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
      </b-carousel>
      <!-- Apartamentos -->
      <b-carousel
        id="carousel-3"
        :interval="2000"
        controls
        indicators
        fade
        background="#ababab"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333;"
      >
        <b-carousel-slide class="shadow" caption="Apartamentos">
          <template #img>
            <img
              class="d-block img-fluid w-100"
              style="width: 495px; height: 250px; object-fit: cover;"
              src="../assets/images/apartamentos-1.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
        <b-carousel-slide class="shadow" caption="Apartamentos">
          <template #img>
            <img
              class="d-block img-fluid w-100"
              style="width: 495px; height: 250px; object-fit: cover;"
              src="../assets/images/apartamentos-2.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
        <b-carousel-slide class="shadow" caption="Apartamentos">
          <template #img>
            <img
              class="d-block img-fluid w-100"
              style="width: 495px; height: 250px; object-fit: cover;"
              src="../assets/images/apartamentos-3.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
        <b-carousel-slide class="shadow" caption="Apartamentos">
          <template #img>
            <img
              class="d-block img-fluid w-100"
              style="width: 495px; height: 250px; object-fit: cover;"
              src="../assets/images/apartamentos-4.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
      </b-carousel>
      <!-- Habitaciones -->
      <b-carousel
        id="carousel-3"
        :interval="2000"
        controls
        indicators
        fade
        background="#ababab"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333;"
      >
        <b-carousel-slide class="shadow" caption="Habitaciones">
          <template #img>
            <img
              class="d-block img-fluid w-100"
              style="width: 495px; height: 250px; object-fit: cover;"
              src="../assets/images/habitaciones-1.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
        <b-carousel-slide class="shadow" caption="Habitaciones">
          <template #img>
            <img
              class="d-block img-fluid w-100"
              style="width: 495px; height: 250px; object-fit: cover;"
              src="../assets/images/habitaciones-2.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
        <b-carousel-slide class="shadow" caption="Habitaciones">
          <template #img>
            <img
              class="d-block img-fluid w-100"
              style="width: 495px; height: 250px; object-fit: cover;"
              src="../assets/images/habitaciones-3.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
        <b-carousel-slide class="shadow" caption="Habitaciones">
          <template #img>
            <img
              class="d-block img-fluid w-100"
              style="width: 495px; height: 250px; object-fit: cover;"
              src="../assets/images/habitaciones-4.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
      </b-carousel>
       <!-- <p>Sendero Ecológico</p> -->
      <b-carousel
        id="carousel-4"
        :interval="2000"
        controls
        indicators
        fade
        background="#ababab"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333;"
      >
        <b-carousel-slide class="shadow" caption="Sendero Ecológico">
          <template #img>
            <img
              class="d-block img-fluid w-100"
              style="width: 495px; height: 250px; object-fit: cover;"
              src="../assets/images/turismo.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
        <b-carousel-slide class="shadow" caption="Sendero Ecológico">
          <template #img>
            <img
              class="d-block img-fluid w-100"
              style="width: 495px; height: 250px; object-fit: cover;"
              src="../assets/images/turismo-1.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
        <b-carousel-slide class="shadow" caption="Sendero Ecológico">
          <template #img>
            <img
              class="d-block img-fluid w-100"
              style="width: 495px; height: 250px; object-fit: cover;"
              src="../assets/images/turismo-2.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
        <b-carousel-slide class="shadow" caption="Sendero Ecológico">
          <template #img>
            <img
              class="d-block img-fluid w-100"
              style="width: 495px; height: 250px; object-fit: cover;"
              src="../assets/images/turismo-3.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
        <b-carousel-slide class="shadow" caption="Sendero Ecológico">
          <template #img>
            <img
              class="d-block img-fluid w-100"
              style="width: 495px; height: 250px; object-fit: cover;"
              src="../assets/images/turismo-4.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
      </b-carousel>
      <!-- Salon de Juegos -->
      <b-carousel
        id="carousel-3"
        :interval="2000"
        controls
        indicators
        fade
        background="#ababab"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333;"
      >
        <b-carousel-slide class="shadow" caption="Salón de Juegos">
          <template #img>
            <img
              class="d-block img-fluid w-100"
              style="width: 495px; height: 250px; object-fit: cover;"
              src="../assets/images/area-de-juegos-3.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
        <b-carousel-slide class="shadow" caption="Salón de Juegos">
          <template #img>
            <img
              class="d-block img-fluid w-100"
              style="width: 495px; height: 250px; object-fit: cover;"
              src="../assets/images/area-de-juegos-1.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
        <b-carousel-slide class="shadow" caption="Salón de Juegos">
          <template #img>
            <img
              class="d-block img-fluid w-100"
              style="width: 495px; height: 250px; object-fit: cover;"
              src="../assets/images/area-de-juegos-2.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
      </b-carousel>
      <!-- Parqueadero -->
      <b-carousel
        id="carousel-4"
        :interval="2000"
        controls
        indicators
        fade
        background="#ababab"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333;"
      >
        <b-carousel-slide class="shadow" caption="Parqueadero">
          <template #img>
            <img
              class="d-block img-fluid w-100"
              style="width: 495px; height: 250px; object-fit: cover;"
              src="../assets/images/parqueadero-3.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
        <b-carousel-slide class="shadow" caption="Parqueadero">
          <template #img>
            <img
              class="d-block img-fluid w-100"
              style="width: 495px; height: 250px; object-fit: cover;"
              src="../assets/images/parqueadero-1.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
        <b-carousel-slide class="shadow" caption="Parqueadero">
          <template #img>
            <img
              class="d-block img-fluid w-100"
              style="width: 495px; height: 250px; object-fit: cover;"
              src="../assets/images/parqueadero-2.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainImage",
};
</script>
<style scoped>
.container-servicios > div:hover {
  opacity: 1;
  transform: scale(1.01);
}
.container-areas {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  padding: 0%;
}
.icon {
  width: 140px;
}
.container-servicios {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 0%;
}
@media screen and (max-width: 720px) {
  .container-areas {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 5px;
    padding: 0%;
  }
  .container-servicios {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}
.subtitle {
  font-family: "Pacifico", cursive;
}
</style>
