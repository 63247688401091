<template>
  <div>
    <Nav />
    <div class="container">
      <Reservas />
      <MainVideos />
      <MainImage />
      <br />
      <Tarifas />
      <br />
      <Nosotros />
      <br />
      <Contactanos />
      <br/>
      <Ubicacion />
      <br />
    </div>
      <BackFooter />
  </div>
</template>

<script>
import Nav          from './Nav.vue';
import Reservas     from './Reservas.vue';
import MainImage    from './MainImage.vue';
import MainVideos   from './MainVideos.vue';
import Tarifas      from './Tarifas.vue';
import Nosotros     from './Nosotros.vue';
import Contactanos  from './Contactanos.vue';
// import Modal        from './Modal.vue';
import Ubicacion    from './Ubicacion.vue';
import BackFooter   from './BackFooter.vue';

export default {
    name: 'Home',
    components: {
        Nav,
        Reservas,
        MainImage,
        MainVideos,
        Tarifas,
        Nosotros,
        Contactanos,
        // Modal,
        Ubicacion,
        BackFooter
    }
}
</script>

<style>
  .container{
    height: 70px;
  }
  hr{
    background-color: #5fa500;
  }
</style>