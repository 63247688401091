<template>
  <div class="" id="nosotros">
    <h4>Nosotros</h4>
    <hr />
    <h5>
      <i>
        Cordialmente ponemos a disposición de ustedes las instalaciones de nuestro
        <b> Hotel Campestre kaidaly 🌄</b>
      </i>
    </h5>
      <ul class="content-videos">
        <li class="shadow">
          <footer class="footer">Lo que somos</footer>
          <video src="../assets/images/video_1.mp4#t=1" controls loop></video>
        </li>    
        <li class="shadow">
          <footer class="footer">Tú Hotel</footer>
          <video src="../assets/images/video_2.mp4#t=1" controls loop></video>
        </li>
      </ul>
    </div>
</template>

<script>
export default {
  name: "Nosotros",
};
</script>
<style scoped>
  
</style>

<style scoped>
  .container-areas {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    padding: 0%;
  }
  .content-videos > li:first-child{
    width: 98.3%;
  }
  .container-servicios {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    padding: 0%;
  }
  .carousel-inner {
    border-radius: 8px;
  }
  @media screen and (max-width: 720px) {
    .container-areas {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 5px;
      padding: 0%;
    }
}
</style>
