<template>
  <div class="content-nav">
    <b-navbar toggleable="lg" type="dark" class="nav-background">
      <b-navbar-brand href="#">
        <img class="image-icon" src="../assets/Kaidaly.png" alt="Kaidaly"/>
      </b-navbar-brand>
      <b-navbar-toggle
        target="nav-collapse"
        class="menuHamburguer"
      ></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav> </b-navbar-nav>
        <b-navbar-nav class="ml-auto text-center">
          <b-nav-item href="#" class="router" v-scroll-to="'#mainImage'">
            Inicio
          </b-nav-item>
          <b-nav-item href="#" class="router" v-scroll-to="'#recomendaciones'">
            Recomendaciones
          </b-nav-item>
          <b-nav-item href="#" class="router" v-scroll-to="'#nuestraFinca'">
            Servicios
          </b-nav-item>
          <b-nav-item href="#" class="router" v-scroll-to="'#tarifas'">
            Tarifas
          </b-nav-item>
          <b-nav-item href="#" class="router" v-scroll-to="'#nosotros'">
            Nosotros
          </b-nav-item>
          <b-nav-item href="#" class="router" v-scroll-to="'#contactanos'">
            Contactanos
          </b-nav-item>
          <b-nav-item href="#" class="router" v-scroll-to="'#ubicacion'">
            Ubicación
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Nav",
};
</script>

<style scoped>
.router {
  margin-left: 7px;
}
.router:last-child {
  margin-right: 10px;
}
.image-icon {
  width: 12%;
}
.nav-background {
  background-color: #ff9a02;
}
.content-nav {
  position: fixed;
  z-index: 100;
  width: 100%;
}
.navbar-brand {
  display: contents;
  text-decoration: none;
}
.navbar {
  padding-right: 0px;
  padding-bottom: 0px;
}
.menuHamburguer {
  border: none;
}
a {
  color: white !important;
}

.navbar-nav {
  margin-left: 10px;
}
.nav-link:hover {
  border-bottom: 1px solid;
}
@media screen and (max-width: 820px) {
  .nav-link {
    width: 95%;
  }
  .router {
    /* padding-top: 2px; */
    /* padding-bottom: 10px; */
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .router:nth-child(n + 1) {
    margin-top: 20px;
  }
  .router:first-child {
    margin-top: -210px;
  }
  .navbar {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .navbar-nav {
    height: 200px;
    margin-left: auto !important;
  }
}
</style>
