<template>
    <div id="ubicacion">
        <h4>Ubicación</h4>
        <hr />
        <div style="w-100 border border-warning mb-5 shadow">
            <iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=es&amp;q=+(Hotel%20kaidaly)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
            </iframe>
        </div>
        <br><br><br><br>
    </div>
</template>
<script>
    export default {
        name: 'Ubicacion',
    }
</script>
<style>
</style>