<template>
  <div class="container-contacta" id="contactanos">
      <h4>Contáctanos</h4>
      <hr />
        <p class="title text-success">
          Estamos esperandoté, comunicaté con nosotros, tenemos servicios las 24 horas
        </p>
      <div class="container-icons">
        <div v-if="atention">
          <b-button href="tel:3102364843">
            <img class="icon" src="../assets/phone.svg" alt="phone">
            3102364843
          </b-button>
          <b-button href="tel:3123129977" class="ml-2">
            <img class="icon" src="../assets/phone.svg" alt="phone">
            3123129977
          </b-button>
          <b-button href="tel:3112944614" class="button-call">
            <img class="icon" src="../assets/phone.svg" alt="phone">
            3112944614
          </b-button>
        </div>
        <div v-else>
          <b-button @click="alertAtention()">
            <img class="icon" src="../assets/phone.svg" alt="phone">
            3102364843
          </b-button>
          <b-button @click="alertAtention()" class="ml-1">
            <img class="icon" src="../assets/phone.svg" alt="phone">
            3123129977
          </b-button>
          <b-button @click="alertAtention()" class="ml-1">
            <img class="icon" src="../assets/phone.svg" alt="phone">
            3112944614
          </b-button>
        </div>
      </div>
  </div>
</template>

<script>
export default {
    name:'Contactanos',
    data(){
      return{
        getHour:  Number,
        atention: Boolean
      }
    },
    methods:{
      getHourDay(){
        this.getHour  = new Date().getHours()
        this.atention = this.getHour < 22 && this.getHour >= 5 ? true : false;
      },
      alertAtention(){
        this.$swal.fire({
          icon: 'info',
          title: 'No hay horario de atención',
          text: 'Podras llamar después de las 5 de la mañana'
        })
      }
    },
    mounted(){
      this.getHourDay()
    },
}
</script>

<style scoped>
  .container-icons > div > a{
    margin-left: 5px;
  }
  .title{
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  @media screen and (max-width: 720px) {
    .button-call{
      margin-top: 6px;
    }
  }
</style>