<template>
  <div id="recomendaciones">
    <h4>Recomendaciones</h4>
    <hr/>
      <ul class="content-videos">
        <li class="shadow">
          <footer class="footer">Yeison Jimenez</footer>
          <video src="../assets/yeison_jimenez.mp4" controls loop></video>
        </li>    
        <li class="shadow">
          <footer class="footer">Giovanni Ayala</footer>
          <video src="../assets/giovanni_ayala.mp4" controls loop></video>
        </li>
        <li class="shadow">
          <footer class="footer">Yeisman Herreño</footer>
          <video src="../assets/herman_herreno.mp4" controls loop></video>
        </li>
      </ul>
  </div>
</template>

<script>
export default {
    name: 'MainVideos'
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
    h4{
      font-family: 'Pacifico', cursive;
      color: #000000;
    }
    #recomendaciones{
      margin-top: 2%;
    }
    .box{
      width: 100%;
      text-align: center;
    }
    .content-videos{
      display:grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      padding: 0%;
    }
    li{
      list-style: none;
      text-align: center;
    }
    .footer{
      border-bottom: 1px solid #000000;
      font-family: 'Pacifico', cursive;
      margin: auto;
      margin-bottom: 5px;
      width: 50%;
    }
    video{
      width: 100%;
      border-radius: 10px;
      outline: none
    }
    video:hover{
      cursor: pointer;
    }
    @media screen and (max-width: 720px)and (min-width: 360px){
      .content-videos{
        grid-template-columns: repeat(1, 1fr);
      }
      video{
        width: 80%;
        margin: auto;
      }
    }
</style>