<template>
  <div class="container-reservas" id="mainImage">
    <div class="container-image shadow">
        <b-carousel
        id="carousel-1"
        :interval="2500"
        controls
        indicators
        fade
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333;"
      >
        <b-carousel-slide class="shadow">
          <template #img>
            <img
              class="d-block img-fluid w-100 image-home"
              style="width: 2024px; height: 450px; object-fit: cover; border-radius: 5px;"
              src="../assets/8.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
        <b-carousel-slide class="shadow">
          <template #img>
            <img
              class="d-block img-fluid w-100 image-home"
              style="width: 2024px; height: 450px; object-fit: cover; border-radius: 5px;"
              src="../assets/2.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
        <b-carousel-slide class="shadow">
          <template #img>
            <img
              class="d-block img-fluid w-100 image-home"
              style="width: 2024px; height: 450px; object-fit: cover; border-radius: 5px;"
              src="../assets/3.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
        <b-carousel-slide class="shadow">
          <template #img>
            <img
              class="d-block img-fluid w-100 image-home"
              style="width: 2024px; height: 450px; object-fit: cover; border-radius: 5px;"
              src="../assets/images/salon-de-conferencias-1.jpg"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>
    <div class="container-message">
      <b-card
      title="Reserva ya !!"
      tag="artículo"
      class="mb-2 card-reservas"
    >
        <b-card-text>
        No te pierdas de vivir un momento inolvidable en tu
        <b><i>Hotel Campestre Kaidaly 🌄</i></b>
        <i>Paz, </i>
        <i>Naturaleza, </i>
        <i>Diversión y mucho más.</i>
        </b-card-text>
        <div class="container-icons">
          <b-button 
          href="https://api.whatsapp.com/send?phone=573102364843&text=Hola, estoy interesado en información del Hotel Kaidaly" 
          target="_blank" 
          variant="success">
            Reservar
            <img class="icon" src="../assets/whatsapp.svg" alt="whatsapp" />
        </b-button>
        <b-button 
          href="https://api.whatsapp.com/send?phone=573123129977&text=Hola, estoy interesado en información del Hotel Kaidaly" 
          target="_blank" 
          variant="success">
            Reservar
            <img class="icon" src="../assets/whatsapp.svg" alt="whatsapp" />
        </b-button>
        <b-button 
          href="https://api.whatsapp.com/send?phone=573112944614&text=Hola, estoy interesado en información del Hotel Kaidaly" 
          target="_blank" 
          variant="success">
            Reservar
            <img class="icon" src="../assets/whatsapp.svg" alt="whatsapp" />
        </b-button>
        </div>
        <b-button 
          href="https://www.facebook.com/fincakaidaly.ecoturistica" 
          target="_blank" 
          variant="primary" class="button-facebook">
            <img class="icon facebook" src="../assets/facebook.png" alt="facebook" />
            Siguenos
        </b-button>
    </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Reservas',
};
</script>

<style>
.container-reservas {
  padding-top: 9%;
  width: 100%;
  border: none;
}
.container-image >img{
    width: 100%;
}
.icon{
  width: 20px;
}
.button-facebook{
  background-color: #3b5998;
  border-color: #3b5998;
}
.container-icons > a{
  margin-right: 2px;
}
.container-icons > a:last-child{
  margin-top: 2px;
}
.facebook{
  width: 25px;
}
.card-reservas{
    position: absolute;
    top: 15.2%;
    left: 68.2%;
    width: 23%;
    background-color: #ff9a02;
    z-index: 1;
}
@media screen and (max-width: 820px){
  .card-reservas{
    position: relative;
    margin-top: 2%;
    width: 100%;
    top: 0;
    left: 0;
  }
  .image-home{
    height: 200px !important;
  }
}
</style>